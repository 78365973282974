import React from "react"
import { Container, Stack } from "react-bootstrap"
import styled from "styled-components"
import { Text } from "../../components/Core"
import { ColumTextStyle } from "./tableTypes/pricingStyles"

export const whatsAppText =
  "Hola, quisiera definir un plan distinto a lo que actualmente me ofrecen."

export const occassionalInfoMessage1 =
  "Si quieres que hagamos todo por ti y eres freelancer ocasional, este es el plan!"
export const occassionalInfoMessage2 =
  "Nos encargamos de todo el proceso para que te paguen y no pierdas tiempo en el papeleo."
export const servicesPresentationMessage = [
  "Si trabajas",
  "para una empresa de manera estable, te recomendamos elegir este plan.",
]

export const advancedPaymentTooltipText =
  "Adelanta tu dinero cuando tus clientes no te pagan inmediato."
export const assistedServiceTooltipText =
  "Somos tu asistente virtual, nos encargamos del registro de proveedor, facturar y cobrar por ti. "

export const allInOneInfoMessage = `Si estás creando una empresa o tienes necesidades diferentes a las que ofrecemos en nuestros planes, podemos crear una experiencia nueva para ti.`

/* This object is used to define the table header
  - text is the title
  - isActive is the flag that indicates if the border color should be the active color or not
  - decorator is the flag that show a static decorator
*/
const headerObjHandler = (text, isActive = false, decorator = false) => ({
  text: text,
  isActive: isActive,
  decorator: decorator,
})

// TODO change the licenseId as same of backend
/* This object is used to define the description of the plan
  - cost is the value of the plan
  - description is the description of the plan
  - licenseId is the id that need to be the same as the backend
  - discountPercentage is the value of the discount that is optional
  - quota is the value of the quotas that is optional
  - isActive is the flag that indicates if the border color should be the active color or not
*/

const subHeaderObjHandler = (
  cost,
  description,
  licenseId,
  discountPercentage = "",
  quota = "",
  isActive = false,
  removeMarginTop = false
) => ({
  cost: cost,
  description: description,
  licenseId: licenseId,
  discountPercentage: discountPercentage,
  quota: quota,
  isActive: isActive,
  removeMarginTop: removeMarginTop,
})

/* This object is used to define the services that include in the  plan
  - name is a text that will be shown in the table normally used when describe the plan
  - iconType is the type of icon that will be shown in the table `include` shows the checkIcon or `exclude` shows the closeIcon
  - isActive is the flag that indicates if the border color should be the active color or not
*/

const serviceObjHandler = (
  name,
  iconType = "",
  isActive = false,
  tooltip = ""
) => ({
  name: name,
  iconType: iconType,
  isActive: isActive,
  tooltip: tooltip,
})

export const SubtitleText = styled(Text)`
  font-weight: 400;
  font-size: 0.95rem;
  line-height: 20px;
  color: #3a5660;
`

export const PriceText = styled(Text)`
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 28px;
  color: ${({ theme }) => theme.colors.primary};
`

export const MonthText = styled(Text)`
  font-weight: 400;
  font-size: 1rem;
  line-height: 20px;
  color: #3a5660;
`

export const GrayText = styled.span`
  font-weight: 400;
  font-size: 0.95rem;
  line-height: 20px;
  color: #777a89;
`

// Check where we use the list if add or remove services
const allInOneServices = [
  "Crea y envía cuentas de cobro",
  "Programa recordatorios para cobrar a tus clientes",
  "Asistente virtual",
  "Recibe pagos desde el exterior.",
  "Afiliación de seguridad social y pago a través de nuestra plataforma.",
  "Anticipa el pago de tus cuentas de cobro*",
]

/* The structure of the plan is the following:
  - headerObj is the object that contains the header of the plan
  - subHeaderObj is the object that contains the description of the plan
  - services is the array that contains the services that include in the plan
  note: Allways the first array of the services is the description of the services, the other are if inclue or not or is is ilimit
*/

export const annuallyAllInOnePlans = {
  headersCol: [
    headerObjHandler(
      <Stack gap={3}>
        <Container>
          &#128640; Membresía anual todo en uno
          <br />
          <SubtitleText>Para vivir libre de preocupaciones</SubtitleText>
        </Container>
        <Container>
          <Stack gap={1} direction="horizontal">
            <PriceText>$12,500</PriceText> <MonthText>/ Mes</MonthText>
          </Stack>
          <MonthText>
            Pagarás <b>$150,000</b> por los 12 meses
          </MonthText>
        </Container>
      </Stack>,
      true,
      true
    ),
  ],
  subHeadersCol: [],
  services: [
    [
      serviceObjHandler(allInOneServices[0]),
      serviceObjHandler("", "include"),
      serviceObjHandler("", "include", true),
    ],
    [
      serviceObjHandler(allInOneServices[1]),
      serviceObjHandler("", "include"),
      serviceObjHandler("", "include", true),
    ],
    [
      serviceObjHandler(
        <>{allInOneServices[2]}</>,
        "",
        false,
        advancedPaymentTooltipText
      ),
      serviceObjHandler("", "include"),
      serviceObjHandler("", "include", true),
    ],
    [
      serviceObjHandler(allInOneServices[3]),
      serviceObjHandler("", "include"),
      serviceObjHandler("", "include", true),
    ],
    [
      serviceObjHandler(allInOneServices[4]),
      serviceObjHandler("", "include"),
      serviceObjHandler("", "include", true),
    ],
    [
      serviceObjHandler(
        <ColumTextStyle style={{ textAlign: "start" }}>
          {allInOneServices[5]}
          <GrayText>*Aplica T&#38;C</GrayText>
        </ColumTextStyle>
      ),
      serviceObjHandler("", "include"),
      serviceObjHandler("", "include", true),
    ],
  ],
}

export const monthlyAllInOnePlans = {
  headersCol: [
    headerObjHandler("Trabajador por prestación de servicios"),
    headerObjHandler("Freelancer", true),
  ],
  subHeadersCol: [
    subHeaderObjHandler(
      "$9.900",
      <React.Fragment>Mensual</React.Fragment>,
      5,
      null,
      null,
      false,
      true
    ),
    subHeaderObjHandler(
      "$19.900",
      <React.Fragment>Mensual</React.Fragment>,
      4,
      null,
      null,
      true,
      true
    ),
  ],
  services: [
    [
      serviceObjHandler(allInOneServices[0]),
      serviceObjHandler("Ilimitado para 1 cliente", ""),
      serviceObjHandler("Ilimitado", "", true),
    ],
    [
      serviceObjHandler(allInOneServices[1]),
      serviceObjHandler("", "include"),
      serviceObjHandler("", "include", true),
    ],
    [
      serviceObjHandler(allInOneServices[2]),
      serviceObjHandler("", "include"),
      serviceObjHandler("", "include", true),
    ],
    [
      serviceObjHandler(allInOneServices[3]),
      serviceObjHandler("", "include"),
      serviceObjHandler("", "include", true),
    ],
    [
      serviceObjHandler(allInOneServices[4]),
      serviceObjHandler("", "include"),
      serviceObjHandler("", "include", true),
    ],
    [
      serviceObjHandler(allInOneServices[5]),
      serviceObjHandler("", "include"),
      serviceObjHandler("", "include", true),
    ],
    [
      serviceObjHandler(allInOneServices[6]),
      serviceObjHandler("", "exclude"),
      serviceObjHandler("", "include", true),
    ],
    [
      serviceObjHandler(
        allInOneServices[7],
        "",
        false,
        advancedPaymentTooltipText
      ),
      serviceObjHandler("", "exclude"),
      serviceObjHandler("", "include", true),
    ],
    [
      serviceObjHandler(
        allInOneServices[8],
        "",
        false,
        assistedServiceTooltipText
      ),
      serviceObjHandler("", "exclude"),
      serviceObjHandler("", "include", true),
    ],
  ],
}
