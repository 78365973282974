import PropTypes from "prop-types"
import React, { memo, useContext, useState } from "react"
import { Card, Col, Container, Modal, Row, Stack } from "react-bootstrap"
import styled from "styled-components"
import GlobalContext from "../../../context/GlobalContext"
import { default as checkIcon } from "../../../images/svg/icons/checkIcon.svg"
import ArrowIcon from "../cards/ArrowIcon"
import PlanCard from "../cards/PlanCard"
import {
  annuallyAllInOnePlans,
  GrayText,
  monthlyAllInOnePlans,
  PriceText,
  SubtitleText,
} from "../pricingData"
import { ColumTextStyle, ResponsiveDivStyle } from "./pricingStyles"
import { licensePathHandler, textHandler } from "./utils"

const QuestionSvgStyled = styled.img`
  margin-left: 5px;
`

const CardStyled = styled(Card)`
  border: 2.5px solid ${({ theme }) => theme.colors.linebreak};
  border-radius: 8px;
  padding: 1rem;
  width: 85%;
  margin: 0;

  @media (max-width: 992px) {
    margin-top: 1.5rem;
    justify-content: center;
  }

  @media (min-width: 556px) {
    width: 70%;
  }

  @media (max-width: 770px) {
    margin: 1.5rem auto;
  }
`
const Divider = styled.hr`
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
`
const ImgStyled = styled.img`
  height: 1.25rem;
`

export const SmallButton = styled.button`
  margin: 0.75rem 0;
  width: 13rem;
  min-height: 3rem;
  background: transparent;
  color: ${({ theme }) => theme.colors.primary};
  letter-spacing: 0.02em;

  border: 1px solid #dfe1e9;
  box-sizing: border-box;
  border-radius: 40px;
  font-weight: 500;

  img {
    margin-top: -5px;
    marging-left: 2px;
  }

  &:hover {
    background: ${({ theme }) => theme.colors.textHover};
    border-radius: 40px;
    text-align: center;
    color: #ffffff;
    transition-timing-function: ease-in-out;
    -webkit-transition-timing-function: ease-in-out;
  }

  @media (max-width: 992px) {
    width: 10rem;
  }
`
const AllInOne = ({ annuallyPlans, monthlyPlans }) => {
  const [subSection, setSubSection] = useState("annually")
  const [dialogOpen, setDialogOpen] = useState(false)

  const { referralCode: code, userEmail } = useContext(GlobalContext)
  const handleSetDialogOpen = () => setDialogOpen(prevState => !prevState)

  const handleCardClick = licenseId => {
    if (licenseId === 1 && userEmail) {
      handleSetDialogOpen()
      return
    }
    if (licenseId && userEmail) {
      const redirectUrl = licensePathHandler(licenseId, code, userEmail)
      window.open(redirectUrl, "_blank")
      return
    }
    window.open(licensePathHandler(licenseId, code, userEmail), "_blank")
  }

  const DisabledModal = () => (
    <Modal show={dialogOpen} onHide={handleSetDialogOpen}>
      <Modal.Header closeButton>
        <Modal.Title>¡Agotaste tu plan gratuito!</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Para seguir utilizando Factcil, selecciona un plan que se acomode a tus
        necesidades.
      </Modal.Body>
    </Modal>
  )

  return (
    <React.Fragment>
      <Container
        css={`
          padding: 0;
          margin-bottom: 3rem;
        `}
      >
        <ResponsiveDivStyle>
          {subSection === "annually"
            ? annuallyAllInOnePlans?.headersCol.map((col, index) => (
                <>
                  <Col
                    md={6}
                    sm={12}
                    key={`resposive-card-col-${index}`}
                    style={{
                      padding: 0,
                      display: "flex",
                      justifyContent: "end",
                      alignItems: "center",
                    }}
                  >
                    <CardStyled>
                      <Card.Body
                        style={{ padding: 0, justifyContent: "center" }}
                      >
                        <Stack gap={3}>
                          <Container>
                            {textHandler("Un servicio", "700")}
                            <SubtitleText>
                              Para freelancers ocasionales
                            </SubtitleText>
                          </Container>
                          <Container>
                            <Stack gap={1} direction="horizontal">
                              <PriceText>$100,000</PriceText>{" "}
                              <SubtitleText>
                                <b>+ IVA + 1%</b>
                              </SubtitleText>
                            </Stack>
                            <SubtitleText>
                              Se hará un único cobro válido por un mes
                            </SubtitleText>
                          </Container>
                        </Stack>
                        <Divider />
                        <Row key={`service-0`} style={{ margin: ".5rem 0" }}>
                          <Stack direction="horizontal" gap={4}>
                            <ColumTextStyle style={{ textAlign: "start" }}>
                              <GrayText>
                                Hacemos las tareas con las que los freelancers
                                normalmente no quieren lidiar:
                              </GrayText>
                            </ColumTextStyle>
                          </Stack>
                        </Row>
                        <Row
                          key={`service-1`}
                          style={{ margin: "2rem 0 .5rem" }}
                        >
                          <Stack direction="horizontal" gap={4}>
                            <ImgStyled src={checkIcon} alt="checkIcon" />
                            <ColumTextStyle style={{ textAlign: "start" }}>
                              Registro de proveedor con la empresa que te
                              contrató
                            </ColumTextStyle>
                          </Stack>
                        </Row>
                        <Row key={`service-2`} style={{ margin: ".5rem 0" }}>
                          <Stack direction="horizontal" gap={4}>
                            <ImgStyled src={checkIcon} alt="checkIcon" />
                            <ColumTextStyle style={{ textAlign: "start" }}>
                              Enviamos tu cuenta de cobro
                            </ColumTextStyle>
                          </Stack>
                        </Row>
                        <Row key={`service-3`} style={{ margin: ".5rem 0" }}>
                          <Stack direction="horizontal" gap={4}>
                            <ImgStyled src={checkIcon} alt="checkIcon" />
                            <ColumTextStyle style={{ textAlign: "start" }}>
                              Facturamos por ti
                            </ColumTextStyle>
                          </Stack>
                        </Row>
                        <Row key={`service-4`} style={{ margin: ".5rem 0" }}>
                          <Stack direction="horizontal" gap={4}>
                            <ImgStyled src={checkIcon} alt="checkIcon" />
                            <ColumTextStyle style={{ textAlign: "start" }}>
                              Cobramos por ti
                            </ColumTextStyle>
                          </Stack>
                        </Row>
                        <Row key={`service-5`} style={{ margin: ".5rem 0" }}>
                          <Stack direction="horizontal" gap={4}>
                            <ImgStyled src={checkIcon} alt="checkIcon" />
                            <ColumTextStyle style={{ textAlign: "start" }}>
                              Te afiliamos a seguridad social
                            </ColumTextStyle>
                          </Stack>
                        </Row>
                        <Row key={`service-6`} style={{ margin: ".5rem 0" }}>
                          <Stack direction="horizontal" gap={4}>
                            <ImgStyled src={checkIcon} alt="checkIcon" />
                            <ColumTextStyle style={{ textAlign: "start" }}>
                              Solicita el anticipo de pago de tu cuenta de
                              cobro*
                            </ColumTextStyle>
                          </Stack>
                        </Row>
                        <Row
                          key={`service-7`}
                          style={{ margin: "2rem 0 .5rem" }}
                        >
                          <Stack direction="horizontal" gap={4}>
                            <ColumTextStyle style={{ textAlign: "start" }}>
                              <GrayText>
                                *El 1% corresponde al valor de la cuenta de
                                cobro.
                              </GrayText>
                            </ColumTextStyle>
                          </Stack>
                        </Row>
                        <Row key={`service-8`} style={{ margin: ".5rem 0" }}>
                          <Stack direction="horizontal" gap={4}>
                            <ColumTextStyle style={{ textAlign: "start" }}>
                              <GrayText>
                                Aplica términos y condiciones.
                              </GrayText>
                            </ColumTextStyle>
                          </Stack>
                        </Row>
                        <Col
                          style={{
                            justifyContent: "center",
                            marginTop: "2rem",
                            display: "flex",
                          }}
                        >
                          <SmallButton onClick={() => handleCardClick(7)}>
                            Escoger plan{"\u00A0"}
                            {"\u00A0"}
                            <span
                              css={`
                                align-self: end;
                              `}
                            >
                              <ArrowIcon color="#002432" />
                            </span>
                          </SmallButton>
                        </Col>
                      </Card.Body>
                    </CardStyled>
                  </Col>
                  <Col
                    md={6}
                    sm={12}
                    key={`resposive-col-${index}`}
                    style={{ padding: 0 }}
                  >
                    <PlanCard
                      key={`resposive-card-${index}`}
                      title={col.text}
                      descriptionObj={
                        annuallyAllInOnePlans?.subHeadersCol.length > 0
                          ? annuallyAllInOnePlans?.subHeadersCol[index]
                          : []
                      }
                      services={annuallyAllInOnePlans?.services}
                      headerIndex={index}
                      isActive={col.isActive}
                      hasDecorator={col.decorator}
                      handleCardClick={handleCardClick}
                    />
                  </Col>
                </>
              ))
            : monthlyPlans?.headersCol.map((col, index) => (
                <Col md={6} sm={12} key={`resposive-col-${index}`}>
                  <PlanCard
                    key={`resposive-card-${index}`}
                    title={col.text}
                    descriptionObj={monthlyPlans?.subHeadersCol[index]}
                    services={monthlyPlans?.services}
                    headerIndex={index}
                    isActive={col.isActive}
                    hasDecorator={col.decorator}
                    handleCardClick={handleCardClick}
                  />
                </Col>
              ))}
          <DisabledModal />
        </ResponsiveDivStyle>
      </Container>
    </React.Fragment>
  )
}

AllInOne.propTypes = {
  annuallyPlans: PropTypes.object,
  monthlyPlans: PropTypes.object,
}

AllInOne.defaultProps = {
  annuallyPlans: annuallyAllInOnePlans,
  monthlyPlans: monthlyAllInOnePlans,
}

export default memo(AllInOne)
