import PropTypes from "prop-types"
import React, { useState } from "react"
import { Card, Col, Modal, Row, Stack } from "react-bootstrap"
import styled from "styled-components"
import { Text } from "../../../components/Core"
import { default as checkIcon } from "../../../images/svg/icons/checkIcon.svg"
import QuestionSvg from "../../../images/svg/icons/Vector.svg"
import {
  ColumTextStyle,
  Decorator,
  DescriptionSpan,
  DescriptionSpanWithoutMargin,
  DiscountSpan,
  FeesSpan,
  SelectPlanBtn,
} from "../tableTypes/pricingStyles"
import { textHandler } from "../tableTypes/utils"
import ArrowIcon from "./ArrowIcon"

const CardStyled = styled(Card)`
  border: 2.5px solid
    ${({ theme, active }) =>
      active ? theme.colors.textHover : theme.colors.linebreak};
  border-radius: 8px;
  text-align: center;
  width: 85%;

  @media (min-width: 768px) {
    min-height: 100%;
  }

  @media (max-width: 770px) {
    margin: 1.5rem auto;
  }

  @media (min-width: 556px) {
    width: 75%;
  }
`

const PricesDiv = styled.div`
  display: flex;
  minheight: 1.8rem;
  justify-content: center;
  padding: 1.5rem 0 1rem 0;
`

const TextStyled = styled(Text)`
  font-size: 1rem;
  margin: auto 0;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: ${({ theme }) => theme.colors.primary};
  font-weight: 700;
`
const ImgStyled = styled.img`
  height: 1.25rem;
`

const CardDecorator = styled(Decorator)`
  width: 5.313rem;
  height: 1.938rem;
  right: 26rem;
`

const Divider = styled.hr`
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
`
const QuestionSvgStyled = styled.img`
  margin-left: 5px;
`

export const GrayText = styled.span`
  font-weight: 400;
  font-size: 0.95rem;
  line-height: 20px;
  color: #777a89;
`

const PlanCard = ({
  title,
  descriptionObj,
  services,
  headerIndex,
  isActive,
  btnText,
  hasDecorator,
  includeTitle,
  referralCode,
  handleCardClick,
}) => {
  const [isModalOpened, setIsModalOpened] = useState(false)

  const handleChangeDialogState = () => {
    setIsModalOpened(prev => !prev)
  }

  // This fucntion return the text of services that the plan include according to the list of services
  const servicesAvailableHandler = (service, indexHeader, serviceIndex) => {
    if (
      service &&
      (service[indexHeader + 1]?.name ||
        service[indexHeader + 1]?.iconType === "include")
    ) {
      return (
        <Row
          key={`service-${serviceIndex}`}
          style={{
            margin: ".5rem 0",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          <Col xs={1}>
            <ImgStyled src={checkIcon} alt="checkIcon" />
          </Col>
          <Col xs={11}>
            <Row
              key={`service-description-${serviceIndex}`}
              style={{
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Col xs={service[0]?.tooltip ? 10 : 12}>
                <ColumTextStyle style={{ textAlign: "start" }}>
                  {service[0]?.name}
                </ColumTextStyle>
              </Col>
              {service[0]?.tooltip && (
                <Col xs={2}>
                  <QuestionSvgStyled
                    src={QuestionSvg}
                    className="ms-auto"
                    onClick={handleChangeDialogState}
                    style={{ cursor: "pointer" }}
                  />
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      )
    }
    return null
  }
  return (
    <React.Fragment>
      <CardStyled
        key={headerIndex}
        active={isActive ? isActive.toString() : undefined}
      >
        {hasDecorator && <CardDecorator>Popular</CardDecorator>}
        <Card.Body style={{ textAlign: "left" }}>
          {textHandler(title, "700")}
          <Divider />
          <Col>
            {descriptionObj.length > 0 && (
              <Stack direction="Vertical">
                <PricesDiv>
                  {<TextStyled>{descriptionObj?.cost}</TextStyled>}
                  {descriptionObj?.discountPercentage && (
                    <DiscountSpan>{`Ahorras ${descriptionObj?.discountPercentage}%`}</DiscountSpan>
                  )}
                </PricesDiv>
                {descriptionObj?.removeMarginTop ? (
                  <DescriptionSpanWithoutMargin>
                    {descriptionObj?.description}
                  </DescriptionSpanWithoutMargin>
                ) : (
                  <DescriptionSpan>
                    {descriptionObj?.description}
                  </DescriptionSpan>
                )}
                {descriptionObj?.quota && (
                  <FeesSpan>{`Paga en ${descriptionObj?.quota} cuotas sin intereses`}</FeesSpan>
                )}
              </Stack>
            )}
            <Col>
              {services &&
                services.map((service, index) =>
                  servicesAvailableHandler(service, headerIndex, index)
                )}
              <Row key={`service-7`} style={{ margin: "2rem 0 .5rem" }}>
                <Stack direction="horizontal" gap={4}>
                  <ColumTextStyle style={{ textAlign: "start" }}>
                    <GrayText>
                      *El 1% corresponde al valor de la cuenta de cobro.
                    </GrayText>
                  </ColumTextStyle>
                </Stack>
              </Row>
              <Row key={`service-8`} style={{ margin: ".5rem 0" }}>
                <Stack direction="horizontal" gap={4}>
                  <ColumTextStyle style={{ textAlign: "start" }}>
                    <GrayText>Aplica términos y condiciones.</GrayText>
                  </ColumTextStyle>
                </Stack>
              </Row>
            </Col>
            {
              <Col
                style={{
                  justifyContent: "center",
                  display: "flex",
                  marginTop: "2rem",
                  marginBottom: "1rem",
                  width: "100%",
                }}
              >
                <SelectPlanBtn
                  active={isActive}
                  onClick={() => handleCardClick(2)}
                >
                  Escoger plan{"\u00A0"}
                  {"\u00A0"}
                  <ArrowIcon color="#FFFFFF" />
                </SelectPlanBtn>
              </Col>
            }
          </Col>
        </Card.Body>
      </CardStyled>
      <Modal show={isModalOpened} centered onHide={handleChangeDialogState}>
        <Modal.Header
          closeButton
          css={{
            ".btn-close": {
              background: "$pink100",
              color: "red",
            },
          }}
          style={{ padding: "2rem" }}
        >
          <Modal.Title style={{ marginTop: "3rem" }}>
            {textHandler("Asistente virtual", "700")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: "2rem" }}>
          <TextStyled style={{ fontWeight: "400" }}>
            Tu asistente se encargará de:
          </TextStyled>
          <TextStyled style={{ fontWeight: "400", marginTop: "1.5rem" }}>
            1. Registrar tu proveedor
          </TextStyled>
          <TextStyled style={{ fontWeight: "400", marginTop: ".25rem" }}>
            2. Liquidar tu planilla de seguridad social y/o afiliarte.
          </TextStyled>
          <TextStyled style={{ fontWeight: "400", marginTop: ".25rem" }}>
            3. Crear y enviar tu cuenta de cobro
          </TextStyled>
          <TextStyled style={{ fontWeight: "400", marginTop: ".25rem" }}>
            4. Cobrar por ti. (Aplica cuando no selecciones que te adelantemos
            el dinero).
          </TextStyled>
          <TextStyled style={{ fontWeight: "400", marginTop: ".75rem" }}>
            *Este servicio tiene un costo adicional de $70,000.
          </TextStyled>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  )
}

PlanCard.propTypes = {
  title: PropTypes.string,
  descriptionObj: PropTypes.object,
  services: PropTypes.array,
  headerIndex: PropTypes.number,
  isActive: PropTypes.bool,
  btnText: PropTypes.string,
  hasDecorator: PropTypes.bool,
  includeTitle: PropTypes.string,
  referralCode: PropTypes.string,
}

PlanCard.defaultProps = {
  isActive: false,
  btnText: "Escoger Plan",
  hasDecorator: false,
  includeTitle: "Servicios",
}

export default PlanCard
